import type { ConfidenceLevel } from '../types';

export const adviceTemplates: Record<ConfidenceLevel, string[]> = {
  low: [
    "I'm not too sure, but maybe it's ${hint}?",
    "Hmm, I could be wrong, but I think it might be ${hint}.",
    "Honestly, I'm just guessing here, but maybe ${hint}?",
    "This is just a guess, but I think it could be ${hint}."
  ],
  medium: [
    "I'm leaning toward ${hint}, but it's not a definite answer.",
    "I feel like it could be ${hint}.",
    "I think ${hint} seems right, but I'm not 100% certain.",
    "${hint} seems likely, though I could be mistaken."
  ],
  high: [
    "Without a doubt, it's ${hint}.",
    "I'm certain it's ${hint}.",
    "I'm very sure it's ${hint}.",
    "I'd say with confidence that it's ${hint}."
  ]
};