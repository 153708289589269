import type { QuizResponse } from '../types';

export async function submitQuizResults(
  playerId: string, 
  results: QuizResponse[], 
  clarityUserId?: string,
  qref?: string
) {
  try {
    const payload = {
      playerId: playerId,
      clarityId: clarityUserId,
      quizReference: qref,
      answers: results
    };

    //if clarityId is not provided, check localstorage
    if (!clarityUserId) {
      const clarityId = localStorage.getItem('clarityUserId');
      if (clarityId) {
        payload.clarityId = clarityId;
      }
    }

    // console.log(payload);

    const result = await fetch('/api/result', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    });
    return result.ok;
  } catch (error) {
    console.error('Failed to submit quiz results:', error);
    return false;
  }
}