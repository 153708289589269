import type { QuizQuestion } from '../types';

export const questions: QuizQuestion[] = [
  {
    id: 0,
    image: "https://4f82bcec.aiste-psych.pages.dev/images/RMET_P.jpg",
    question: "What emotion do you perceive in these eyes?",
    options: ["Curious", "Worried", "Excited", "Tired"],
    correctAnswer: "Curious"
  },
  {
    id: 1,
    image: "https://4f82bcec.aiste-psych.pages.dev/images/RMET_1.jpg",
    question: "What emotion do you perceive in these eyes?",
    options: ["Playful", "Comforting", "Irritated", "Bored"],
    correctAnswer: "Playful"
  },
  {
    id: 2,
    image: "https://4f82bcec.aiste-psych.pages.dev/images/RMET_2.jpg",
    question: "What emotion do you perceive in these eyes?",
    options: ["Terrified", "Upset", "Arrogant", "Annoyed"],
    correctAnswer: "Upset"
  },
  {
    id: 3,
    image: "https://4f82bcec.aiste-psych.pages.dev/images/RMET_3.jpg",
    question: "What emotion do you perceive in these eyes?",
    options: ["Joking", "Flustered", "Desire", "Convinced"],
    correctAnswer: "Desire"
  },
  {
    id: 4,
    image: "https://4f82bcec.aiste-psych.pages.dev/images/RMET_4.jpg",
    question: "What emotion do you perceive in these eyes?",
    options: ["Joking", "Insisting", "Amused", "Relaxed"],
    correctAnswer: "Insisting"
  },
  {
    id: 5,
    image: "https://4f82bcec.aiste-psych.pages.dev/images/RMET_5.jpg",
    question: "What emotion do you perceive in these eyes?",
    options: ["Irritated", "Sarcastic", "Worried", "Friendly"],
    correctAnswer: "Worried"
  },
  {
    id: 6,
    image: "https://4f82bcec.aiste-psych.pages.dev/images/RMET_6.jpg",
    question: "What emotion do you perceive in these eyes?",
    options: ["Aghast", "Fantasizing", "Impatient", "Alarmed"],
    correctAnswer: "Fantasizing"
  },
  {
    id: 7,
    image: "https://4f82bcec.aiste-psych.pages.dev/images/RMET_7.jpg",
    question: "What emotion do you perceive in these eyes?",
    options: ["Apologetic", "Friendly", "Uneasy", "Dispirited"],
    correctAnswer: "Uneasy"
  },
  {
    id: 8,
    image: "https://4f82bcec.aiste-psych.pages.dev/images/RMET_8.jpg",
    question: "What emotion do you perceive in these eyes?",
    options: ["Despondent", "Relieved", "Shy", "Excited"],
    correctAnswer: "Despondent"
  },
  {
    id: 9,
    image: "https://4f82bcec.aiste-psych.pages.dev/images/RMET_9.jpg",
    question: "What emotion do you perceive in these eyes?",
    options: ["Annoyed", "Hostile", "Horrified", "Preoccupied"],
    correctAnswer: "Preoccupied"
  },
  {
    id: 10,
    image: "https://4f82bcec.aiste-psych.pages.dev/images/RMET_10.jpg",
    question: "What emotion do you perceive in these eyes?",
    options: ["Cautious", "Insisting", "Bored", "Aghast"],
    correctAnswer: "Cautious"
  },
  {
    id: 11,
    image: "https://4f82bcec.aiste-psych.pages.dev/images/RMET_11.jpg",
    question: "What emotion do you perceive in these eyes?",
    options: ["Terrified", "Amused", "Regretful", "Flirtatious"],
    correctAnswer: "Regretful"
  },
  {
    id: 12,
    image: "https://4f82bcec.aiste-psych.pages.dev/images/RMET_12.jpg",
    question: "What emotion do you perceive in these eyes?",
    options: ["Indifferent", "Embarrassed", "Skeptical", "Dispirited"],
    correctAnswer: "Skeptical"
  },
  {
    id: 13,
    image: "https://4f82bcec.aiste-psych.pages.dev/images/RMET_13.jpg",
    question: "What emotion do you perceive in these eyes?",
    options: ["Decisive", "Anticipating", "Threatening", "Shy"],
    correctAnswer: "Anticipating"
  },
  {
    id: 14,
    image: "https://4f82bcec.aiste-psych.pages.dev/images/RMET_14.jpg",
    question: "What emotion do you perceive in these eyes?",
    options: ["Irritated", "Disappointed", "Depressed", "Accusing"],
    correctAnswer: "Accusing"
  },
  {
    id: 15,
    image: "https://4f82bcec.aiste-psych.pages.dev/images/RMET_15.jpg",
    question: "What emotion do you perceive in these eyes?",
    options: ["Contemplative", "Flustered", "Encouraging", "Amused"],
    correctAnswer: "Contemplative"
  },
  {
    id: 16,
    image: "https://4f82bcec.aiste-psych.pages.dev/images/RMET_16.jpg",
    question: "What emotion do you perceive in these eyes?",
    options: ["Irritated", "Thoughtful", "Encouraging", "Sympathetic"],
    correctAnswer: "Thoughtful"
  },
  {
    id: 17,
    image: "https://4f82bcec.aiste-psych.pages.dev/images/RMET_17.jpg",
    question: "What emotion do you perceive in these eyes?",
    options: ["Doubtful", "Affectionate", "Playful", "Aghast"],
    correctAnswer: "Doubtful"
  },
  {
    id: 18,
    image: "https://4f82bcec.aiste-psych.pages.dev/images/RMET_18.jpg",
    question: "What emotion do you perceive in these eyes?",
    options: ["Decisive", "Amused", "Aghast", "Bored"],
    correctAnswer: "Decisive"
  },
  {
    id: 19,
    image: "https://4f82bcec.aiste-psych.pages.dev/images/RMET_19.jpg",
    question: "What emotion do you perceive in these eyes?",
    options: ["Arrogant", "Grateful", "Sarcastic", "Tentative"],
    correctAnswer: "Tentative"
  },
  {
    id: 20,
    image: "https://4f82bcec.aiste-psych.pages.dev/images/RMET_20.jpg",
    question: "What emotion do you perceive in these eyes?",
    options: ["Dominant", "Friendly", "Guilty", "Horrified"],
    correctAnswer: "Friendly"
  },
  {
    id: 21,
    image: "https://4f82bcec.aiste-psych.pages.dev/images/RMET_21.jpg",
    question: "What emotion do you perceive in these eyes?",
    options: ["Embarrassed", "Fantasizing", "Confused", "Panicked"],
    correctAnswer: "Fantasizing"
  },
  {
    id: 22,
    image: "https://4f82bcec.aiste-psych.pages.dev/images/RMET_22.jpg",
    question: "What emotion do you perceive in these eyes?",
    options: ["Preoccupied", "Grateful", "Insisting", "Imploring"],
    correctAnswer: "Preoccupied"
  },
  {
    id: 23,
    image: "https://4f82bcec.aiste-psych.pages.dev/images/RMET_23.jpg",
    question: "What emotion do you perceive in these eyes?",
    options: ["Contented", "Apologetic", "Defiant", "Curious"],
    correctAnswer: "Defiant"
  },
  {
    id: 24,
    image: "https://4f82bcec.aiste-psych.pages.dev/images/RMET_24.jpg",
    question: "What emotion do you perceive in these eyes?",
    options: ["Pensive", "Irritated", "Excited", "Hostile"],
    correctAnswer: "Pensive"
  },
  {
    id: 25,
    image: "https://4f82bcec.aiste-psych.pages.dev/images/RMET_25.jpg",
    question: "What emotion do you perceive in these eyes?",
    options: ["Panicked", "Incredulous", "Despondent", "Interested"],
    correctAnswer: "Interested"
  },
  {
    id: 26,
    image: "https://4f82bcec.aiste-psych.pages.dev/images/RMET_26.jpg",
    question: "What emotion do you perceive in these eyes?",
    options: ["Alarmed", "Shy", "Hostile", "Anxious"],
    correctAnswer: "Hostile"
  },
  {
    id: 27,
    image: "https://4f82bcec.aiste-psych.pages.dev/images/RMET_27.jpg",
    question: "What emotion do you perceive in these eyes?",
    options: ["Joking", "Cautious", "Arrogant", "Reassuring"],
    correctAnswer: "Cautious"
  },
  {
    id: 28,
    image: "https://4f82bcec.aiste-psych.pages.dev/images/RMET_28.jpg",
    question: "What emotion do you perceive in these eyes?",
    options: ["Interested", "Joking", "Affectionate", "Contented"],
    correctAnswer: "Interested"
  },
  {
    id: 29,
    image: "https://4f82bcec.aiste-psych.pages.dev/images/RMET_29.jpg",
    question: "What emotion do you perceive in these eyes?",
    options: ["Impatient", "Aghast", "Irritated", "Reflective"],
    correctAnswer: "Reflective"
  },
  {
    id: 30,
    image: "https://4f82bcec.aiste-psych.pages.dev/images/RMET_30.jpg",
    question: "What emotion do you perceive in these eyes?",
    options: ["Grateful", "Flirtatious", "Hostile", "Disappointed"],
    correctAnswer: "Flirtatious"
  },
  {
    id: 31,
    image: "https://4f82bcec.aiste-psych.pages.dev/images/RMET_31.jpg",
    question: "What emotion do you perceive in these eyes?",
    options: ["Ashamed", "Confident", "Joking", "Dispirited"],
    correctAnswer: "Confident"
  },
  {
    id: 32,
    image: "https://4f82bcec.aiste-psych.pages.dev/images/RMET_32.jpg",
    question: "What emotion do you perceive in these eyes?",
    options: ["Serious", "Ashamed", "Bewildered", "Alarmed"],
    correctAnswer: "Serious"
  },
  {
    id: 33,
    image: "https://4f82bcec.aiste-psych.pages.dev/images/RMET_33.jpg",
    question: "What emotion do you perceive in these eyes?",
    options: ["Embarrassed", "Guilty", "Fantasizing", "Concerned"],
    correctAnswer: "Concerned"
  },
  {
    id: 34,
    image: "https://4f82bcec.aiste-psych.pages.dev/images/RMET_34.jpg",
    question: "What emotion do you perceive in these eyes?",
    options: ["Aghast", "Baffled", "Distrustful", "Terrified"],
    correctAnswer: "Distrustful"
  },
  {
    id: 35,
    image: "https://4f82bcec.aiste-psych.pages.dev/images/RMET_35.jpg",
    question: "What emotion do you perceive in these eyes?",
    options: ["Puzzled", "Nervous", "Insisting", "Contemplative"],
    correctAnswer: "Nervous"
  },
  {
    id: 36,
    image: "https://4f82bcec.aiste-psych.pages.dev/images/RMET_36.jpg",
    question: "What emotion do you perceive in these eyes?",
    options: ["Ashamed", "Nervous", "Suspicious", "Indecisive"],
    correctAnswer: "Suspicious"
  }
];