import { useState, useEffect } from 'react';
import { questions } from './data/questions';
import QuizCard from './components/QuizCard';
import type { QuizResponse, ClarityResponse, GameState } from './types';
import { Brain, PlayCircle, MessageCircle, ArrowRight, ExternalLink } from 'lucide-react';
import { submitQuizResults } from './services/database';
import { generatePlayerId } from './utils/id';

declare global {
  interface Window {
    clarity: (command: string, ...args: any[]) => Promise<ClarityResponse>;
  }
}

export default function App() {
  const [gameStarted, setGameStarted] = useState(false);
  const [practiceStage, setPracticeStage] = useState<'intro' | 'practice1' | 'practice2-intro' | 'practice2' | 'main-intro' | 'main'>('intro');
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1);
  const [responses, setResponses] = useState<QuizResponse[]>([]);
  const [isComplete, setIsComplete] = useState(false);
  const [playerId, setPlayerId] = useState(() => generatePlayerId());
  const [clarityUserId, setClarityUserId] = useState<string>();
  const [gameState, setGameState] = useState<GameState>({});

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const qref = params.get('qref');
    const returnTo = params.get('return_to');
    
    if (qref || returnTo) {
      setGameState({ qref: qref || undefined, returnTo: returnTo || undefined });
    }
  }, []);


  useEffect(() => {
    const identifyUser = async () => {
      try {
        const sessionId = `sess_${playerId}`;
        const response = await window.clarity?.("identify", playerId, sessionId, "welcome");
        if (response?.userId) {
          setClarityUserId(response.userId);
          localStorage.setItem('clarityUserId', response.userId);
        }
      } catch (error) {
        console.error('Failed to identify user in Clarity:', error);
      }
    };

    identifyUser();
  }, [playerId]);

  useEffect(() => {
    const updateClarity = async () => {
      try {
        const sessionId = `sess_${playerId}`;
        let response;
        if (!gameStarted) {
          response = await window.clarity?.("identify", playerId, sessionId, "welcome");
        } else if (isComplete) {
          response = await window.clarity?.("identify", playerId, sessionId, "complete");
        }
        if (response?.userId != null) {
          console.log('Clarity user ID:', response.userId);
          setClarityUserId(response.userId);
          localStorage.setItem('clarityUserId', response.userId);
        }
      } catch (error) {
        console.error('Failed to update Clarity identification:', error);
      }
    };

    updateClarity();
  }, [gameStarted, isComplete, playerId]);

  const resetGame = () => {
    if (gameState.qref) return;
    
    const newPlayerId = generatePlayerId();
    setGameStarted(false);
    setPracticeStage('intro');
    setCurrentQuestionIndex(-1);
    setResponses([]);
    setIsComplete(false);
    setPlayerId(newPlayerId);
    localStorage.removeItem('quizResponses');
    localStorage.removeItem('playerId');
    localStorage.removeItem('clarityUserId');
    localStorage.removeItem('gameState');
  };

  const startGame = () => {
    setGameStarted(true);
    setPracticeStage('practice1');
    setCurrentQuestionIndex(0);
    localStorage.setItem('playerId', playerId);
    if (gameState.qref || gameState.returnTo) {
      localStorage.setItem('gameState', JSON.stringify(gameState));
    }
  };

  const startPractice2 = () => {
    setPracticeStage('practice2');
    setCurrentQuestionIndex(0);
  };

  const startMainQuiz = () => {
    setPracticeStage('main');
    setCurrentQuestionIndex(1);
  };

  const handleAnswer = async (response: QuizResponse) => {
    if (practiceStage === 'practice1') {
      setPracticeStage('practice2-intro');
    } else if (practiceStage === 'practice2') {
      if (!response.adviceViewed) {
        return;
      }
      setPracticeStage('main-intro');
    } else if (practiceStage === 'main') {
      const updatedResponses = [...responses, response];
      setResponses(updatedResponses);
      localStorage.setItem('quizResponses', JSON.stringify(updatedResponses));
      
      if (currentQuestionIndex === questions.length - 1) {
        setIsComplete(true);
        await submitQuizResults(playerId, updatedResponses, clarityUserId, gameState.qref);
        localStorage.removeItem('quizResponses');
        localStorage.removeItem('playerId');
        localStorage.removeItem('clarityUserId');
        localStorage.removeItem('gameState');
      } else {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      }
    }
  };

  useEffect(() => {
    const savedPlayerId = localStorage.getItem('playerId');
    const savedResponses = localStorage.getItem('quizResponses');
    const savedClarityUserId = localStorage.getItem('clarityUserId');
    const savedGameState = localStorage.getItem('gameState');
    
    if (savedPlayerId && savedResponses) {
      const parsedResponses = JSON.parse(savedResponses);
      setPlayerId(savedPlayerId);
      setResponses(parsedResponses);
      setGameStarted(true);
      setPracticeStage('main');
      setCurrentQuestionIndex(parsedResponses.length + 1);
      if (savedClarityUserId) {
        setClarityUserId(savedClarityUserId);
      }
      if (savedGameState) {
        setGameState(JSON.parse(savedGameState));
      }
    }
  }, []);

  const handleReturnToSurvey = () => {
    if (gameState.returnTo) {
      window.location.href = gameState.returnTo;
    }
  };

  const renderScreen = () => {
    const commonClasses = "min-h-[100dvh] flex items-center justify-center p-4 bg-gradient-to-br from-blue-50 to-indigo-50 dark:from-gray-900 dark:to-blue-950";
    const cardClasses = "max-w-2xl w-full bg-white dark:bg-gray-800 rounded-2xl shadow-lg dark:shadow-gray-900/30 p-8";

    if (!gameStarted) {
      return (
        <div className={commonClasses}>
          <div className={cardClasses}>
            <div className="text-center mb-8">
              <Brain className="w-16 h-16 mx-auto text-blue-500 dark:text-blue-400 mb-4" />
              <h1 className="text-3xl font-bold text-gray-900 dark:text-gray-100 mb-4">
                What do the eyes say?
              </h1>
              <p className="text-lg text-gray-600 dark:text-gray-300 mb-6 leading-relaxed">
                In this task you will have to choose the word that best describes what the person in the picture is thinking or feeling, based on their eyes.
              </p>
              <p className="text-gray-600 dark:text-gray-300 leading-relaxed">
                We'll start with two practice rounds to help you get familiar with the challenge.
              </p>
            </div>
            <button
              onClick={startGame}
              className="w-full flex items-center justify-center gap-2 bg-blue-500 dark:bg-blue-600 text-white rounded-lg px-6 py-3 
                       hover:bg-blue-600 dark:hover:bg-blue-500 transition-colors duration-200 font-medium"
            >
              <PlayCircle className="w-5 h-5" />
              <span>Start Practice</span>
            </button>
          </div>
        </div>
      );
    }

    if (practiceStage === 'practice2-intro') {
      return (
        <div className={commonClasses}>
          <div className={cardClasses}>
            <div className="text-center mb-8">
              <MessageCircle className="w-16 h-16 mx-auto text-blue-500 dark:text-blue-400 mb-4" />
              <h1 className="text-3xl font-bold text-gray-900 dark:text-gray-100 mb-4">
                Meet Alex, Your Assistant
              </h1>
              <p className="text-lg text-gray-600 dark:text-gray-300 mb-6 leading-relaxed">
                In the next practice round, you'll be introduced to Alex, who can provide advice when you're unsure about an answer.
              </p>
              <p className="text-gray-600 dark:text-gray-300 leading-relaxed mb-6">
                Click the <b>"Ask Alex"</b> button to get their perspective on the question. For this practice round, you must try asking Alex before proceeding.
              </p>
            </div>
            <button
              onClick={startPractice2}
              className="w-full flex items-center justify-center gap-2 bg-blue-500 dark:bg-blue-600 text-white rounded-lg px-6 py-3 
                       hover:bg-blue-600 dark:hover:bg-blue-500 transition-colors duration-200 font-medium"
            >
              <PlayCircle className="w-5 h-5" />
              <span>Continue to Practice</span>
            </button>
          </div>
        </div>
      );
    }

    if (practiceStage === 'main-intro') {
      return (
        <div className={commonClasses}>
          <div className={cardClasses}>
            <div className="text-center mb-8">
              <Brain className="w-16 h-16 mx-auto text-blue-500 dark:text-blue-400 mb-4" />
              <h1 className="text-3xl font-bold text-gray-900 dark:text-gray-100 mb-4">
                Ready to Begin
              </h1>
              <p className="text-lg text-gray-600 dark:text-gray-300 mb-6 leading-relaxed">
                Great job with the practice rounds! You're now ready to start.
              </p>
              <p className="text-gray-600 dark:text-gray-300 leading-relaxed mb-6">
                Remember, Alex is always available to help if you need advice on any question.
              </p>
            </div>
            <button
              onClick={startMainQuiz}
              className="w-full flex items-center justify-center gap-2 bg-blue-500 dark:bg-blue-600 text-white rounded-lg px-6 py-3 
                       hover:bg-blue-600 dark:hover:bg-blue-500 transition-colors duration-200 font-medium"
            >
              <ArrowRight className="w-5 h-5" />
              <span>Start Challenge</span>
            </button>
          </div>
        </div>
      );
    }

    if (isComplete) {
      return (
        <div className={commonClasses}>
          <div className={cardClasses}>
            <div className="text-center">
              <Brain className="w-16 h-16 mx-auto text-blue-500 dark:text-blue-400 mb-4" />
              <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-100 mb-4">Challenge Complete!</h2>
              <p className="text-gray-600 dark:text-gray-300 mb-6">
                Thank you for sharing your perspectives. Your unique viewpoints have been recorded.
              </p>
              <div className="space-y-3">
                {gameState.returnTo ? (
                  <button
                    onClick={handleReturnToSurvey}
                    className="w-full inline-flex items-center justify-center gap-2 bg-blue-500 dark:bg-blue-600 text-white rounded-lg px-4 py-2 
                             hover:bg-blue-600 dark:hover:bg-blue-500 transition-colors duration-200"
                  >
                    <span>Return to Survey</span>
                    <ExternalLink className="w-4 h-4" />
                  </button>
                ) : !gameState.qref && (
                  <button
                    onClick={resetGame}
                    className="w-full bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 rounded-lg px-4 py-2 
                             hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors duration-200"
                  >
                    Start New Challenge
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }

    const totalQuestions = questions.length - 1;
    const questionNumber = practiceStage === 'main' ? currentQuestionIndex : 0;

    return (
      <div className={commonClasses}>
        <QuizCard
          question={questions[practiceStage === 'main' ? currentQuestionIndex : 0]}
          onAnswer={handleAnswer}
          totalQuestions={totalQuestions}
          currentQuestion={questionNumber}
          playerId={playerId}
          isPractice={practiceStage !== 'main'}
          requireAdvice={practiceStage === 'practice2'}
        />
      </div>
    );
  };

  return renderScreen();
}