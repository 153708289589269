import { X, MessageCircle } from 'lucide-react';
import { useEffect, useMemo } from 'react';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

function AnimatedText({ text }: { text: string }) {
  const words = useMemo(() => text.split(' '), [text]);
  
  return (
    <p className="text-base sm:text-lg leading-relaxed">
      {words.map((word, index) => (
        <span
          key={index}
          className="word-reveal"
          style={{
            animationDelay: `${index * 0.1}s`,
            marginRight: '0.25em'
          }}
        >
          {word}
        </span>
      ))}
    </p>
  );
}

export default function Modal({ isOpen, onClose, children }: ModalProps) {
  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') onClose();
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
      document.body.style.overflow = 'unset';
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div 
        className="absolute inset-0 bg-black/50 dark:bg-black/70 backdrop-blur-sm"
        onClick={onClose}
      />
      <div className="relative bg-white dark:bg-gray-800 rounded-2xl shadow-xl dark:shadow-gray-900/30 
                    w-full max-w-[720px] m-4">
        <div className="flex items-center justify-between p-4 border-b border-gray-100 dark:border-gray-700">
          <div className="flex items-center gap-2">
            <MessageCircle className="w-5 h-5 text-blue-500 dark:text-blue-400" />
            <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100">Your assistant Alex says...</h3>
          </div>
          <button
            onClick={onClose}
            className="p-1.5 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
            aria-label="Close dialog"
          >
            <X className="w-5 h-5 text-gray-500 dark:text-gray-400" />
          </button>
        </div>
        <div className="p-4">
          <div className="relative">
            <div className="bg-blue-500 dark:bg-blue-600 text-white p-4 rounded-2xl rounded-tl-none
                          shadow-lg">
              <AnimatedText text={children as string} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}