import { useState, useEffect, useRef } from 'react';
import { MessageCircle, ArrowRight } from 'lucide-react';
import type { QuizQuestion, QuizResponse, ConfidenceLevel } from '../types';
import Modal from './Modal';
import { QuestionService } from '../services/questionService';

interface QuizCardProps {
  question: QuizQuestion;
  onAnswer: (response: QuizResponse) => void;
  totalQuestions: number;
  currentQuestion: number;
  playerId: string;
  isPractice: boolean;
  requireAdvice?: boolean;
}

export default function QuizCard({ 
  question, 
  onAnswer, 
  totalQuestions, 
  currentQuestion,
  playerId,
  isPractice,
  requireAdvice
}: QuizCardProps) {
  const [showAdvice, setShowAdvice] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState<string | null>(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [adviceText, setAdviceText] = useState<string>('');
  const [hasViewedAdvice, setHasViewedAdvice] = useState(false);
  
  const startTimeRef = useRef<number | null>(null);
  const adviceRef = useRef<{
    text: string;
    suggestedAnswer: string;
    correct: boolean;
    level: ConfidenceLevel;
    variation: number;
  } | null>(null);
  const questionServiceRef = useRef<QuestionService>(new QuestionService(playerId));

  useEffect(() => {
    const identifyUser = async () => {
      try {
        const sessionId = `sess_${playerId}`;
        const pageId = isPractice ? `practice${question.id}` : `q${question.id}`;
        const response = await window.clarity?.("identify", playerId, sessionId, pageId);
        if (response?.userId != null) {
          localStorage.setItem('clarityUserId', response.userId);
        }
      } catch (error) {
        console.error('Failed to identify user in Clarity for question:', error);
      }
    };
    identifyUser();
  }, [playerId, question.id, isPractice]);

  const handleImageLoad = () => {
    setImageLoaded(true);
    startTimeRef.current = Date.now();
  };

  const handleImageError = (e: React.SyntheticEvent<HTMLImageElement>) => {
    console.error('Failed to load image:', e);
  };

  const handleAnswer = (answer: string) => {
    setSelectedAnswer(answer);
  };

  const handleProceed = () => {
    if (!selectedAnswer || !startTimeRef.current) return;
    
    const timeSpent = Number(((Date.now() - startTimeRef.current) / 1000).toFixed(2));
    const isCorrect = selectedAnswer === question.correctAnswer;
    
    const response: QuizResponse = {
      questionId: question.id,
      selectedAnswer,
      timeSpent,
      submitTimestamp: Date.now(),
      adviceViewed: hasViewedAdvice,
      ...(adviceRef.current && {
        adviceText: adviceRef.current.text,
        adviceLevel: adviceRef.current.level,
        adviceVariation: adviceRef.current.variation,
        adviceCorrect: adviceRef.current.suggestedAnswer === question.correctAnswer,
        adviceSuggestedAnswer: adviceRef.current.suggestedAnswer,
        adviceFollowed: selectedAnswer === adviceRef.current.suggestedAnswer
      }),
      isCorrect
    };

    onAnswer(response);
    setSelectedAnswer(null);
    setImageLoaded(false);
    setShowAdvice(false);
    setAdviceText('');
    setHasViewedAdvice(false);
    adviceRef.current = null;
  };

  const handleAdviceToggle = () => {
    if (!showAdvice && !adviceText) {
      const adviceResponse = questionServiceRef.current.generateAdviceResponse(
        question.id,
        question.correctAnswer
      );
      adviceRef.current = {
        text: adviceResponse.text,
        suggestedAnswer: adviceResponse.suggestedAnswer,
        correct: adviceResponse.suggestedAnswer === question.correctAnswer,
        level: adviceResponse.confidenceLevel,
        variation: adviceResponse.variation
      };
      setAdviceText(adviceResponse.text);
    }
    setShowAdvice(!showAdvice);
    setHasViewedAdvice(true);
  };

  useEffect(() => {
    startTimeRef.current = null;
    adviceRef.current = null;
    setAdviceText('');
    setShowAdvice(false);
    setHasViewedAdvice(false);
    
    const currentImg = new Image();
    currentImg.src = question.image;
    if (currentImg.complete) {
      handleImageLoad();
    } else {
      currentImg.onload = handleImageLoad;
      currentImg.onerror = handleImageError;
    }

    questionServiceRef.current.preloadNextImage(question.id);

    return () => {
      currentImg.onload = null;
      currentImg.onerror = null;
    };
  }, [question.id, question.image]);

  return (
    <div className="w-full max-w-2xl">
      <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg dark:shadow-gray-900/30 overflow-hidden">
        <div className="border-b border-gray-100 dark:border-gray-700 p-3">
          <div className="inline-flex items-center">
            <span className="bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-100 
                         text-xs font-medium px-3 py-1 rounded-full">
              {isPractice ? 'Practice Round' : `Question ${currentQuestion} of ${totalQuestions}`}
            </span>
          </div>
        </div>

        <div className="relative">
          <div className="aspect-[2.517] relative bg-gray-900">
            {!imageLoaded && (
              <div className="absolute inset-0 bg-gray-200 dark:bg-gray-700 animate-pulse" />
            )}
            <img 
              src={question.image} 
              alt="Question"
              className={`w-full h-full object-contain transition-opacity duration-300 ${
                imageLoaded ? 'opacity-100' : 'opacity-0'
              }`}
              onLoad={handleImageLoad}
              onError={handleImageError}
            />
          </div>
        </div>

        <div className="px-4 py-3 bg-gray-50 dark:bg-gray-800/50 border-y border-gray-100 dark:border-gray-700">
          <h2 className="text-lg font-medium text-gray-900 dark:text-gray-100">
            {question.question}
          </h2>
        </div>

        <div className={`p-3 transition-opacity duration-300 ${imageLoaded ? 'opacity-100' : 'opacity-50 pointer-events-none'}`}>
          <div className="grid md:grid-cols-2 gap-2">
            {question.options.map((option, index) => (
              <button
                key={index}
                onClick={() => handleAnswer(option)}
                disabled={!imageLoaded}
                className={`p-3 text-left rounded-xl transition-all duration-200 shadow-sm hover:shadow-md 
                          ${selectedAnswer === option 
                            ? 'bg-blue-50 dark:bg-blue-900/30 border-2 border-blue-200 dark:border-blue-800' 
                            : 'bg-gray-50 dark:bg-gray-700/50 border-2 border-gray-100 dark:border-gray-700 hover:bg-blue-50/50 dark:hover:bg-blue-900/20 hover:border-blue-200/50 dark:hover:border-blue-800/50'
                          }`}
              >
                <span className={`transition-colors ${
                  selectedAnswer === option 
                    ? 'text-blue-700 dark:text-blue-300' 
                    : 'text-gray-800 dark:text-gray-100'
                }`}>
                  {option}
                </span>
              </button>
            ))}
          </div>
        </div>

        <div className="border-t border-gray-100 dark:border-gray-700 p-3 flex justify-between items-center">
          <button
            onClick={handleAdviceToggle}
            disabled={!imageLoaded}
            className={`flex items-center gap-2 px-3 py-1.5 rounded-lg font-medium
                     text-blue-600 dark:text-blue-400 hover:bg-blue-50 dark:hover:bg-blue-900/20
                     transition-colors duration-200
                     ${!imageLoaded && 'opacity-50 cursor-not-allowed'}
                     ${requireAdvice && !hasViewedAdvice && 'animate-pulse ring-2 ring-blue-400 dark:ring-blue-500'}`}
          >
            <MessageCircle className="w-5 h-5" />
            <span>Ask Alex</span>
          </button>

          <button
            onClick={handleProceed}
            disabled={!selectedAnswer || !imageLoaded || (requireAdvice && !hasViewedAdvice)}
            className={`flex items-center gap-2 px-4 py-2 rounded-lg font-medium transition-all duration-200
                      ${selectedAnswer && imageLoaded && (!requireAdvice || hasViewedAdvice)
                        ? 'bg-blue-500 text-white hover:bg-blue-600 dark:bg-blue-600 dark:hover:bg-blue-500' 
                        : 'bg-gray-100 text-gray-400 cursor-not-allowed dark:bg-gray-700 dark:text-gray-500'
                      }`}
          >
            <span>Continue</span>
            <ArrowRight className="w-4 h-4" />
          </button>
        </div>

        {requireAdvice && !hasViewedAdvice && (
          <div className="p-3 bg-blue-50 dark:bg-blue-900/30 border-t border-blue-100 dark:border-blue-800">
            <p className="text-sm text-blue-700 dark:text-blue-300 text-center">
              For this practice round, try asking Alex for advice before continuing
            </p>
          </div>
        )}
      </div>

      <Modal
        isOpen={showAdvice}
        onClose={() => setShowAdvice(false)}
      >
        {adviceText}
      </Modal>
    </div>
  );
}