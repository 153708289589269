import type { QuizQuestion, ConfidenceLevel } from '../types';
import { questions } from '../data/questions';
import { adviceTemplates } from '../data/advice';
import { seededRandom } from '../utils/id';

interface AdviceResponse {
  text: string;
  confidenceLevel: ConfidenceLevel;
  variation: number;
  suggestedAnswer: string;
}

export class QuestionService {
  private playerId: string;
  private allQuestions: QuizQuestion[];

  constructor(playerId: string) {
    this.playerId = playerId;
    this.allQuestions = questions;
  }

  getQuestion(questionId: number): QuizQuestion | undefined {
    return this.allQuestions.find(q => q.id === questionId);
  }

  getAllQuestions(): QuizQuestion[] {
    return this.allQuestions;
  }

  generateAdviceResponse(questionId: number, correctAnswer: string): AdviceResponse {
    const question = this.getQuestion(questionId);
    if (!question) throw new Error(`Question not found: ${questionId}`);

    const random = seededRandom(`${this.playerId}-${questionId}`);
    
    // Determine if we'll give correct answer (50% chance)
    const giveCorrectAnswer = random() < 0.5;
    
    // Get suggested answer
    let suggestedAnswer: string;
    if (giveCorrectAnswer) {
      suggestedAnswer = correctAnswer;
    } else {
      // Get incorrect options
      const incorrectOptions = question.options.filter(opt => opt !== correctAnswer);
      const randomIndex = Math.floor(random() * incorrectOptions.length);
      suggestedAnswer = incorrectOptions[randomIndex];
    }
    
    // Determine confidence level
    const confidenceLevels: ConfidenceLevel[] = ['low', 'medium', 'high'];
    const confidenceLevel = confidenceLevels[Math.floor(random() * confidenceLevels.length)];
    
    // Pick variation
    const variations = adviceTemplates[confidenceLevel];
    const variationIndex = Math.floor(random() * variations.length);
    
    return {
      text: variations[variationIndex].replace('${hint}', suggestedAnswer),
      confidenceLevel,
      variation: variationIndex + 1,
      suggestedAnswer
    };
  }

  preloadNextImage(currentQuestionId: number): void {
    const currentIndex = this.allQuestions.findIndex(q => q.id === currentQuestionId);
    if (currentIndex < this.allQuestions.length - 1) {
      const nextQuestion = this.allQuestions[currentIndex + 1];
      const img = new Image();
      img.src = nextQuestion.image;
    }
  }
}